import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import MailTo from '@mdx/MailTo';
import Anchor from '@mdx/Anchor';
import Img from '@mdx/Img';
import { Box, Divider, Para, Display, Label } from 'workspace-core-ui';
import Score from '@mdx/Score';
import Confetti from '@components/Confetti';
import Level from '@mdx/Level';
import ScoreMap from '@mdx/ScoreMap';

const shortcodes = {
  // html overrides
  a: Anchor,
  img: Img,
  undefined: 'div',
  para: Para,
  Para,
  // custom components
  Divider,
  Box,
  Score,
  Level,
  Display,
  Label,
  confetti: Confetti,
  Confetti,
  mailto: MailTo,
  MailTo,
  scoremap: ScoreMap,
  ScoreMap,
};

export default ({ children }) => {
  // loosely check if what was passed in is an mdx compliant string
  if (children?.includes('isMDXComponent = true')) {
    return (
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{children}</MDXRenderer>
      </MDXProvider>
    );
  }
  return children;
};
