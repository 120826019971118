// regex to extract script content from a string
const scriptRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/gi;

const getCDataContent = (tag: string | null) => {
  if (!tag) return '';
  // cdata regex
  const cdataRegex = /<!\[CDATA\[([\s\S]*?)\]\]>/gm;
  return cdataRegex.exec(tag)?.[1];
};

/** splits an svg gator svg html chunk into a version without script tags, and then an array or singular portion of the script contents only. These inner portions and scripts need to be wrapped in the relevant rendering apis such as dangerouslySetHtml and react helmet with script tags respectively */
const splitSvgGatorHtml = (html: string): [string, string | null] => {
  // get script from src using scriptRegex, we also check for svg-gator as a soft validation the injected code is valid
  const svgScriptTags =
    html?.includes('__SVGATOR_PLAYER__') && html.match(scriptRegex);

  // if we have a script tag(s), grab cdata from them
  const svgScriptTagsContent =
    svgScriptTags?.length > 0 &&
    svgScriptTags?.map(e => {
      const cData = getCDataContent(e);
      return cData;
    });

  // remove script from html string using scriptRegex
  const srcWithoutScript = html?.replace(scriptRegex, '');

  return [srcWithoutScript, svgScriptTagsContent];
};

export default splitSvgGatorHtml;
