/**
 * This is most likely temporary, the idea here is that we return the correct variants given a string
 * the variant is calculated based on the string length passed in
 */

// if our text is too long, we reduce font-size to prevent issues on smaller devices
const MAX_BODY_TEXT_LEN = 300;

const getSizedParaVariant = (paraText: string): string => {
  const textLen = paraText.length;

  if (textLen > MAX_BODY_TEXT_LEN) {
    return 'fitToSmallScreen';
  }

  return 'p2';
};

export default getSizedParaVariant;
